import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { RegisterFormComponent } from './register-form.component';

const routes: Routes = [
  // Module is lazy loaded, see app-routing.module.ts
  { path: '', component: RegisterFormComponent, data: { title: 'register.title' } },
];

@NgModule({
  imports: [RouterModule.forChild(routes), TranslocoModule],
  exports: [RouterModule],
  providers: [],
})
export class RegisterFromRoutingModule {}
