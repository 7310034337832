import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterFormComponent } from './register-form.component';
import { RegisterFromRoutingModule } from './register-form-routing.module';
import { MaterialModule } from '@app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { I18nModule } from '@app/i18n';
import { PasswordStrengthMeterComponent } from 'angular-password-strength-meter';

@NgModule({
  declarations: [RegisterFormComponent],
  imports: [
    CommonModule,
    MaterialModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    RegisterFromRoutingModule,
    I18nModule,
    PasswordStrengthMeterComponent,
  ],
  exports: [RegisterFormComponent],
})
export class RegisterFormModule {}
